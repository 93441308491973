import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        user: null,
        corp: {},
        qrcode: "",
        gongzhonghao: "",
        lat: "",
        lng: "",
    },
    actions: {},
    getters: {
        getUser(state) {
            return state.user;
        },
        getCorp(state) {
            return state.corp;
        },
        getGongzhonghao(state) {
            return state.gongzhonghao;
        },

        getLat(state) {
            return state.lat;
        },
        getLng(state) {
            return state.lng;
        },
        getQrcode(state) {
            return state.qrcode;
        },
    },
    mutations: {
        addUser(state, data) {
            //变更状态
            state.user = data;
        },

        addQrcode(state, data) {
            state.qrcode = data;
        },
        addGongzhonghao(state, data) {
            state.gongzhonghao = data;
        },
        addLat(state, data) {
            state.lat = data;
        },
        addLng(state, data) {
            state.lng = data;
        },
        delUser(state) {
            state.user = null;
        },
        addCorp(state, data) {
            //变更状态
            state.corp = data;
        },
        delCorp(state) {
            state.corp = {};
        },
    },
});

export default store;
