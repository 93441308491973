<template>
    <div class="page-content">
        <PageHeader></PageHeader>
        <Navbar></Navbar>
        <transition name="fade-transform" mode="out-in">
            <!-- <keep-alive> -->
            <router-view :key="$route.path" />
            <!-- </keep-alive> -->
        </transition>
        <PageFooter class="footer"></PageFooter>
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import Navbar from "@/components/Navbar.vue";

export default {
    name: "layout",
    components: { Navbar, PageFooter, PageHeader },
};
</script>



<style scoped lang="less">
.page-content {
    overflow: hidden;
    width: 100%;
    position: relative;
    min-height: 110vh;
    background-image: url("../images/indexBg.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    // .footer {
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    // }
}
</style>
