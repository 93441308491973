<template>
    <div class="header">
        <div class="box">
            <div class="box-right">
                您好：欢迎来到华源资产网
                <div class="" v-if="$store.getters.getUser">
                    <span style="color: #333; cursor: pointer">
                        <span @click="member" style="margin-right: 10px">
                            {{
                                $store.getters.getUser.nickname == null
                                    ? "默认昵称"
                                    : $store.getters.getUser.nickname
                            }}</span
                        >
                        <span @click="logout" style="color: #1747a5"
                            >退出</span
                        ></span
                    >
                    <span
                        style="
                            color: #1747a5;
                            cursor: pointer;
                            margin-left: 20px;
                        "
                        v-if="$store.getters.getUser.show_statics == 1"
                        @click="DataBigScreenClick"
                        >进入数据大屏</span
                    >
                    <!-- v-if="$store.getters.getUser.show_statics == 1" -->
                </div>

                <div class="" v-else>
                    <span @click="goLogin" style="cursor: pointer"
                        >亲，请登录</span
                    >
                    丨
                    <span
                        style="color: #999999; cursor: pointer"
                        @click="goRegister"
                        >免费注册</span
                    >
                </div>
            </div>
            <div>
                <!-- <span>
                    24小时客服热线：{{ $store.getters.getCorp.mobile }}
                </span> -->
                <span
                    style="margin-left: 20px; cursor: pointer"
                    @click="goHelp"
                >
                    帮助中心
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { removeToken } from "@/utils/auth";
export default {
    name: "PageHeader",
    data() {
        return {};
    },
    mounted() {
        // console.log(this.$store.getters.getUser.nickname);
    },
    watch: {
        $route: "fetchData",
    },
    methods: {
        fetchData() {},
        goRegister() {
            this.$router.push({
                path: "/Register",
            });
            localStorage.setItem("activeIndex", "");
        },
        goLogin() {
            this.$router.push({
                path: "/Login",
            });
            localStorage.setItem("activeIndex", "");
        },
        logout() {
            this.$confirm("是否退出登录")
                .then((res) => {
                    removeToken();
                    this.$store.commit("delUser");
                    this.$router.push({
                        path: "/Login",
                    });
                })
                .catch((res) => {
                    // // console.log(res);
                });
        },
        member() {
            this.$router.push({
                path: "/Member",
            });
            localStorage.setItem("activeIndex", "");
        },
        DataBigScreenClick() {
            this.$router.push({
                path: "/DataBigScreen",
            });
            localStorage.setItem("activeIndex", "");
        },

        goHelp() {
            this.$router.push({
                path: "/HelpCenter",
            });
            localStorage.setItem("activeIndex", "");
        },
    },
};
</script>



<style scoped lang="less">
.header {
    width: 100%;
    height: 38px;
    background: #f1f1f1;
    .box {
        width: 1440px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        line-height: 38px;
        color: #999999;
        font-size: 16px;
        div {
            div {
                float: right;
                margin-left: 10px;
                span {
                    color: #df2720;
                }
            }
        }
    }
}
</style>
