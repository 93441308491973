<template>
    <div id="app">
        <!--        <PageHeader></PageHeader>-->
        <router-view />
        <!--        <PageFooter></PageFooter>-->
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import { getHome } from "@/api/home";
export default {
    components: {
        PageHeader,
        PageFooter,
    },
    created() {
        getHome().then((res) => {
            // // console.log(res);
            if (res.code == 200) {
                localStorage.setItem("userInfo", JSON.stringify(res.data.user));
                localStorage.setItem("lat", res.data.lat);
                localStorage.setItem("lng", res.data.lng);
                localStorage.setItem("gongzhonghao", res.data.gongzhonghao);
                localStorage.setItem("qrcode", res.data.qrcode);
                this.$store.commit("addUser", res.data.user);
                this.$store.commit("addCorp", res.data.corp);
            }
        });
    },
};
</script>

<style lang="less">
#app {
    width: 100%;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

span {
    margin: 0;
}
.pageContent {
    width: 1440px;
    height: auto;
    margin: 50px auto;
    padding: 20px 40px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 2px 3px 35px 0px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
}
.empty {
    width: 100%;
    height: 400px;
    img {
        display: block;
        width: 260px;
        margin: 100px auto;
    }
}
</style>
