import Vue from "vue";
import VueRouter from "vue-router";
import layout from "@/layout/index.vue";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

export const constantRoutes = [
    {
        path: "/",
        component: layout,
        redirect: "/home",
        children: [
            {
                path: "home",
                name: "home",
                meta: { title: "首页" },
                component: () => import("@/views/Home"),
            },
        ],
    },
    {
        path: "/introduction",
        component: layout,
        redirect: "/introduction/index",
        children: [
            {
                path: "index",
                name: "introduction",
                meta: { title: "推介专区" },
                component: () => import("@/views/Introduction"),
            },
        ],
    },
    {
        path: "/introductionDetail",
        component: layout,
        redirect: "/introductionDetail/index",
        children: [
            {
                path: "index",
                name: "introductionDetail",
                meta: { title: "详情" },
                component: () => import("@/views/Introduction/Detail"),
            },
        ],
    },
    {
        path: "/search",
        component: layout,
        redirect: "/search/index",
        children: [
            {
                path: "index",
                name: "search",
                meta: { title: "搜索" },
                component: () => import("@/views/Search"),
            },
        ],
    },
    {
        path: "/Register",
        component: layout,
        redirect: "/Register/index",
        children: [
            {
                path: "index",
                name: "Register",
                meta: { title: "注册" },
                component: () => import("@/views/Register"),
            },
        ],
    },
    {
        path: "/Login",
        component: layout,
        redirect: "/Login/index",
        children: [
            {
                path: "index",
                name: "Login",
                meta: { title: "登陆" },
                component: () => import("@/views/Login"),
            },
        ],
    },
    {
        path: "/transaction",
        component: layout,
        redirect: "/transaction/index",
        children: [
            {
                path: "index",
                meta: { title: "交易专区" },
                name: "transaction",
                component: () => import("@/views/Transaction"),
            },
        ],
    },
    {
        path: "/transactionDetail",
        component: layout,
        redirect: "/transactionDetail/index",
        children: [
            {
                path: "index",
                name: "transactionDetail",
                meta: { title: "详情" },
                component: () => import("@/views/Transaction/Detail"),
            },
        ],
    },
    {
        path: "/auction",
        component: layout,
        redirect: "/auction/index",
        children: [
            {
                path: "index",
                meta: { title: "拍卖专区" },
                name: "auction",
                component: () => import("@/views/Auction"),
            },
        ],
    },
    {
        path: "/auctionDetail",
        component: layout,
        redirect: "/auctionDetail/index",
        children: [
            {
                path: "index",
                name: "auctionDetail",
                meta: { title: "详情" },
                component: () => import("@/views/Auction/Detail"),
            },
        ],
    },
    {
        path: "/CompanyDynamics",
        component: layout,
        redirect: "/CompanyDynamics/index",
        children: [
            {
                path: "index",
                meta: { title: "公司动态" },
                name: "CompanyDynamics",
                component: () => import("@/views/CompanyDynamics"),
            },
        ],
    },
    {
        path: "/newsDetail",
        component: layout,
        redirect: "/newsDetail/index",
        children: [
            {
                path: "index",
                name: "newsDetail",
                meta: { title: "详情" },
                component: () => import("@/views/CompanyDynamics/NewsDetail"),
            },
        ],
    },
    {
        path: "/Policy",
        component: layout,
        redirect: "/Policy/index",
        children: [
            {
                path: "index",
                meta: { title: "政策法规" },
                name: "Policy",
                component: () => import("@/views/Policy"),
            },
        ],
    },
    {
        path: "/Policy2",
        component: layout,
        redirect: "/Policy2/index",
        children: [
            {
                path: "index",
                meta: { title: "事务所" },
                name: "Policy2",
                component: () => import("@/views/Policy2"),
            },
        ],
    },
    {
        path: "/AboutUs",
        component: layout,
        redirect: "/AboutUs/index",
        children: [
            {
                path: "index",
                meta: { title: "政策法规" },
                name: "AboutUs",
                component: () => import("@/views/AboutUs"),
            },
        ],
    },
    {
        path: "/vipNub",
        component: layout,
        redirect: "/vipNub/index",
        children: [
            {
                path: "index",
                meta: { title: "会员服务" },
                name: "vipNub",
                component: () => import("@/views/VipNub"),
            },
        ],
    },
    {
        path: "/notice",
        component: layout,
        redirect: "/notice/index",
        children: [
            {
                path: "index",
                meta: { title: "公告中心" },
                name: "notice",
                component: () => import("@/views/Notice"),
            },
        ],
    },
    {
        path: "/HelpCenter",
        component: layout,
        redirect: "/HelpCenter/index",
        children: [
            {
                path: "index",
                meta: { title: "帮助中心" },
                name: "HelpCenter",
                component: () => import("@/views/HelpCenter"),
            },
        ],
    },
    {
        path: "/ConfirmOrder",
        component: layout,
        redirect: "/ConfirmOrder/index",
        children: [
            {
                path: "index",
                meta: { title: "确认订单" },
                name: "ConfirmOrder",
                component: () => import("@/views/ConfirmOrder"),
            },
        ],
    },
    {
        path: "/Member",
        component: layout,
        redirect: "/Member/index",
        children: [
            {
                path: "index",
                meta: { title: "会员中心" },
                name: "Member",
                component: () => import("@/views/Member"),
            },
        ],
    },
    {
        path: "/OrderDetail",
        component: layout,
        redirect: "/OrderDetail/index",
        children: [
            {
                path: "index",
                meta: { title: "订单详情" },
                name: "OrderDetail",
                component: () => import("@/views/OrderDetail"),
            },
        ],
    },
    {
        path: "/EnterpriseAuth",
        component: layout,
        redirect: "/EnterpriseAuth/index",
        children: [
            {
                path: "index",
                meta: { title: "企业认证" },
                name: "EnterpriseAuth",
                component: () => import("@/views/EnterpriseAuth"),
            },
        ],
    },
    {
        path: "/DataBigScreen",
        component: () => import("@/views/DataBigScreen/index.vue"),
    },
    {
        path: "/dashboard",
        component: () => import("@/views/DataCenter/index.vue"),
    },
    {
        path: "/404",
        component: () => import("@/views/ErrorPage/404.vue"),
    },
    {
        path: "*",
        redirect: "/404",
    },
];
const createRouter = () =>
    new VueRouter({
        // mode: 'history', // require service support
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRoutes,
    });

const router = createRouter();

/*const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})*/

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;
