import request from "@/utils/request";

export function getHome() {
    return request({
        url: "/api/home",
        method: "get",
    });
}
export function getNewsList(data) {
    return request({
        url: "/api/news/index",
        method: "get",
        params: data,
    });
}
export function getAssetsType(data) {
    return request({
        url: "/api/assets/type",
        method: "get",
        params: data,
    });
}

export function getAssetsIndex(data) {
    return request({
        url: "/api/assets/index",
        method: "post",
        data: data,
    });
}
export function getAssetsImportant(data) {
    return request({
        url: "/api/assets/important",
        method: "post",
        data: data,
    });
}
export function demandStore(data) {
    return request({
        url: "/api/demand/store",
        method: "post",
        data,
    });
}
export function demandList(data) {
    return request({
        url: "/api/demand/index",
        method: "post",
        data,
    });
}
