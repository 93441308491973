 
<template>
    <div>
        <el-image-viewer
            ref="imageViewer"
            style="z-index: 3000"
            :on-close="closeViewer"
            :url-list="urlList"
            :initialIndex="initialImageIndex"
        />
    </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
    name: "imageView",
    components: { ElImageViewer },
    methods: {
        closeViewer() {
            console.log("关闭");
            document.body.style.overflow = "auto";
            document.removeEventListener("wheel", this.preventScroll);
            // 右上方关闭按钮销毁该组件
            document.body.removeChild(
                document.getElementsByClassName("el-image-viewer__wrapper")[0]
            );
        },
        preventScroll(event) {
            event.preventDefault();
        },
    },
    mounted() {
        // 禁用滚轮
        console.log("daozhe");
        document.body.style.overflow = "hidden";
        document.addEventListener("wheel", this.preventScroll, {
            passive: false,
        });
    },
};
</script>
<style scoped lang="less">
::v-deep .el-image-viewer__close {
    color: white;
}
</style>