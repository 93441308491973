<template>
    <div class="">
        <div class="nav">
            <!-- <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      background-color="transparent"
      :router="true"
    >
      <el-menu-item index="/"> 首页 </el-menu-item>

      <el-menu-item index="/introduction"> 推介专区 </el-menu-item>
      <el-menu-item index="/transaction"> 交易专区 </el-menu-item>
      <el-menu-item index="/auction"> 拍卖专区 </el-menu-item>
      <el-menu-item index="/vipNub"> 会员服务 </el-menu-item>
      <el-menu-item index="/notice"> 公告中心 </el-menu-item>
    </el-menu> -->
            <div class="nav-top">
                <div class="left">
                    <img
                        class="logo1"
                        src="../images/logo1.png"
                        style="cursor: pointer"
                        alt=""
                        @click="goMenu('/')"
                    />
                    <span></span>
                    <img
                        class="logo2"
                        src="../images/logo2.png"
                        style="cursor: pointer"
                        alt=""
                        @click="goMenu('/')"
                    />
                </div>
                <div class="center">
                    <img
                        style="cursor: pointer"
                        src="../images/logo3.png"
                        alt=""
                        @click="goMenu('/')"
                    />
                </div>
            </div>
            <div class="windowWidth">
                <div class="left">
                    <ul>
                        <li
                            :class="
                                activeIndex == '/' || activeIndex == '/home'
                                    ? 'active'
                                    : ''
                            "
                            @click="goMenu('/')"
                        >
                            <span>首页</span>
                        </li>
                        <li
                            :class="
                                activeIndex == '/introduction' ? 'active' : ''
                            "
                            @click="goMenu('/introduction')"
                        >
                            <span>推介专区</span>
                        </li>
                        <li
                            :class="
                                activeIndex == '/transaction' ? 'active' : ''
                            "
                            @click="goMenu('/transaction')"
                        >
                            <span>交易专区</span>
                        </li>
                        <li
                            :class="activeIndex == '/auction' ? 'active' : ''"
                            @click="goMenu('/auction')"
                        >
                            <span>拍卖专区</span>
                        </li>
                        <li
                            :class="activeIndex == '/Policy' ? 'active' : ''"
                            @click="goMenu('/Policy')"
                        >
                            <span>政策法规</span>
                        </li>
                        <li
                            :class="activeIndex == '/AboutUs' ? 'active' : ''"
                            @click="goMenuA('/AboutUs')"
                        >
                            <span>关于我们</span>
                        </li>
                    </ul>
                </div>
                <div class="right">
                    <div class="rl">
                        <!-- <img src="../images/search.png" alt="" /> -->
                        <div class="rll">
                            <!-- <span>推介</span>
              <img src="../images/downIcon.png" alt="" /> -->
                            <el-select
                                style="color: #1747a5"
                                v-model="typeId"
                                @change="getTypeId"
                            >
                                <el-option label="推介" value="1"></el-option>
                                <el-option label="交易" value="2"></el-option>
                                <el-option label="拍卖" value="3"></el-option>
                            </el-select>
                            <!-- <select v-model="typeId" @change="getTypeId">
                                <option value="1">推介</option>
                                <option value="2">交易</option>
                                <option value="3">拍卖</option>
                            </select> -->
                        </div>
                        <div class="shu"></div>
                        <input
                            type="text"
                            placeholder="请搜索关键字"
                            v-model="searchText"
                        />
                    </div>
                    <div class="rr" @click="serch">
                        <span>搜索</span>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%; height: 1px; background: #1747a5"></div>
    </div>
</template>

<script>
import MiddleUtil from "../utils/MiddleUtil";
export default {
    name: "Navbar",
    data() {
        return {
            activeIndex: "/",
            searchText: "",
            typeId: "1",
        };
    },
    created() {
        // // console.log(this.$route.name);
        this.activeIndex = "/" + this.$route.name;
    },
    watch: {
        $route: "fetchData",
    },
    methods: {
        fetchData() {
            this.activeIndex = localStorage.getItem("activeIndex");
        },
        goMenu(url) {
            this.activeIndex = url;
            localStorage.setItem("activeIndex", url);
            this.$router.push(url);
        },
        goMenuA(url) {
            this.activeIndex = url;
            localStorage.setItem("activeIndex", url);
            this.$router.push({
                path: url,
                // query: {
                //   type: 3,
                //   title: "关于我们",
                // },
            });
        },
        getTypeId() {
            // console.log(this.typeId);
        },
        serch() {
            if (this.searchText) {
                if (this.typeId == 1) {
                    this.activeIndex = "/introduction";
                    // let list = JSON.parse(localStorage.getItem("historyList"));

                    // if (!list) {
                    //     localStorage.setItem(
                    //         "historyList",
                    //         JSON.stringify([this.searchText])
                    //     );
                    // } else {
                    //     let d = list.concat(this.searchText);
                    //     d = d.filter(
                    //         (item, index) => d.indexOf(item) === index
                    //     );
                    //     localStorage.setItem("historyList", JSON.stringify(d));
                    // }
                }
                if (this.typeId == 2) {
                    this.activeIndex = "/transaction";

                    // let list = JSON.parse(localStorage.getItem("historyList2"));

                    // if (!list) {
                    //     localStorage.setItem(
                    //         "historyList2",
                    //         JSON.stringify([this.searchText])
                    //     );
                    // } else {
                    //     let d = list.concat(this.searchText);
                    //     d = d.filter(
                    //         (item, index) => d.indexOf(item) === index
                    //     );
                    //     localStorage.setItem("historyList2", JSON.stringify(d));
                    // }
                }
                if (this.typeId == 3) {
                    this.activeIndex = "/auction";

                    // let list = JSON.parse(localStorage.getItem("historyList3"));

                    // if (!list) {
                    //     localStorage.setItem(
                    //         "historyList3",
                    //         JSON.stringify([this.searchText])
                    //     );
                    // } else {
                    //     let d = list.concat(this.searchText);
                    //     d = d.filter(
                    //         (item, index) => d.indexOf(item) === index
                    //     );
                    //     localStorage.setItem("historyList3", JSON.stringify(d));
                    // }
                }
            }

            let serchData = {
                type: this.typeId,
                text: this.searchText,
            };

            if (this.$route.path != "/search/index") {
                this.$router.push({
                    path: "/search",
                    query: {
                        keyword: JSON.stringify(serchData),
                    },
                });
            } else {
                MiddleUtil.$emit("searchClick", JSON.stringify(serchData));
            }
            this.searchText = "";
        },
    },
};
</script>

<style scoped lang="less">
.nav {
    width: 1440px;
    height: 272px;
    overflow: hidden;
    margin: 0 auto;
    .nav-top {
        width: 100%;
        height: auto;
        overflow: hidden;
        .left {
            float: left;
            span {
                width: 2px;
                height: 30px;
                background-color: #000;
                float: left;
                margin-top: 78px;
                margin-left: 45px;
                margin-right: 33px;
            }
            .logo1 {
                float: left;
                width: 92px;
                margin-top: 36px;
                margin-left: 40px;
            }
            .logo2 {
                float: left;
                width: 69px;
                margin-top: 36px;
            }
        }
        .center {
            float: left;
            img {
                width: 305px;
                margin-top: 65px;
                margin-left: 283px;
            }
        }
    }
    .windowWidth {
        width: 100%;
        height: calc(100% - 150px);
        overflow: hidden;
        .left {
            float: left;
            height: auto;
            margin-top: 40px;
            ul {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    float: left;
                    width: 140px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    margin-right: 40px;
                    cursor: pointer;
                    border-radius: 30px;
                    font-size: 24px;
                    &:hover {
                        background: linear-gradient(
                            93deg,
                            #004bdc 0%,
                            #1547a9 100%
                        );
                        color: #fff;
                    }
                }
                .active {
                    background: linear-gradient(
                        93deg,
                        #004bdc 0%,
                        #1547a9 100%
                    );
                    color: #fff;
                }
            }
        }
        .right {
            float: right;
            width: 350px;
            height: 50px;
            border: 1px solid #aeaeae;
            border-radius: 22px;
            margin-top: 45px;
            overflow: hidden;
            .rl {
                float: left;
                width: 255px;
                height: 100%;

                .rll {
                    float: left;
                    width: 80px;
                    height: 100%;
                    line-height: 50px;
                    text-align: center;
                    font-size: 18px;
                    margin-left: 10px;
                    ::v-deep .el-select {
                        float: left;
                        width: 100%;
                        height: 100%;
                        background: transparent !important;
                        outline: none;
                        border: none;
                        text-align: center;
                        font-size: 18px;
                        color: #004bdc;
                        .el-input__inner {
                            background: transparent !important;
                            border: none;
                            font-size: 18px;
                            color: #004bdc;
                        }
                        .el-select__caret {
                            font-size: 18px;
                            color: #004bdc;
                        }
                    }
                    ::v-deep .el-input__suffix {
                        top: -3px;
                    }

                    // span {
                    //   float: left;
                    //   margin-left: 15px;
                    //   color: #004bdc;
                    // }
                    // img {
                    //   float: left;
                    //   width: 12px;
                    //   margin-left: 4px;
                    //   margin-top: 20px;
                    // }
                    // &::after {
                    //   content: "";
                    //   float: right;
                    //   width: 1px;
                    //   height: 15px;
                    //   background: #004bdc;
                    //   margin-top: 17.5px;
                    // }
                }
                .shu {
                    float: left;
                    width: 1px;
                    height: 15px;
                    background: #004bdc;
                    margin-top: 17.5px;
                    margin-left: 5px;
                }
                input {
                    float: right;
                    width: calc(100% - 110px);
                    height: 100%;
                    outline: none;
                    border: none;
                    background: transparent;
                    font-size: 18px;
                }
            }
            .rr {
                float: right;
                width: calc(100% - 255px);
                height: 100%;
                text-align: center;
                line-height: 50px;
                background: linear-gradient(70deg, #004bdd 0%, #1747a5 100%);
                color: #fff;
                font-size: 18px;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }
}
</style>
