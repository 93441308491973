//在imageView文件夹下新建imageView.js文件

import ImgView from "./imgView.vue";
export default {
    install(vue) {
        const Constructor = vue.extend(ImgView);
        // 将 $imgView 方法挂载到vue原型上用于全局调用，传入urlList预览图片地址数组
        vue.prototype.$imgView = (urlList, initialImageIndex = 0) => {
            const instance = new Constructor({
                data() {
                    return {
                        urlList: urlList,
                        initialImageIndex: initialImageIndex,
                    };
                },
            });
            const instanceDom = instance.$mount();
            // 将预览图片组件注入到body的子节点
            document.body.appendChild(instanceDom.$el);
        };
    },
};
