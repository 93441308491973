import Cookie from 'js-cookie'

const key = 'Authorization'

export function getToken() {
    return Cookie.get(key)
}

export function setToken(token) {
    return Cookie.set(key, token)
}

export function removeToken() {
    return Cookie.remove(key)
}
