<template>
    <div class="footer">
        <div style="background-color: #f5f5f5; width: 100%; height: 230px">
            <div class="foot-top">
                <img class="left1" src="../images/logofooter.png" alt="" />
                <div class="cen">
                    <div>
                        <img class="left2" :src="qrcode" alt="" />
                        <span>小程序</span>
                    </div>
                    <div>
                        <img class="left2" :src="gongzhonghao" alt="" />
                        <span>公众号</span>
                    </div>
                </div>
                <div class="map">
                    <!-- <div
                        id="containerFoot"
                        style="width: 300px; height: 172px"
                    ></div> -->
                    <img :src="mapImg" alt="" />
                    <div class="boxMap">
                        <div
                            id="containerFoot"
                            style="width: 500px; height: 350px"
                        ></div>
                    </div>
                </div>
                <div class="right">
                    <span class="title">联系我们：</span>
                    <span
                        >电话：<b>{{ $store.getters.getCorp.mobile }}</b>
                    </span>
                    <span>地址：{{ $store.getters.getCorp.address }}</span>
                </div>
            </div>
        </div>
        <div class="foot-bom">
            <div class="menu">
                <span @click="goAboutUs1(2, '交易指引')">交易指引</span> |
                <span @click="goAboutUs1(3, '相关下载')">相关下载</span> |
                <span @click="goAboutUs1(1, '注册协议', 2, 1)">注册协议</span> |
                <span @click="goAboutUs1(1, '隐私协议', 2, 2)">隐私协议</span>
            </div>
            <div>©2023 {{ $store.getters.getCorp.title }} 版权所有</div>
            <a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
            >
                <span> {{ $store.getters.getCorp.beianhao }}</span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageFooter",
    data() {
        return {
            mapImg: "",
            qrcode: "",
            gongzhonghao: "",
        };
    },
    created() {
        this.gongzhonghao = localStorage.getItem("gongzhonghao");
        this.qrcode = localStorage.getItem("qrcode");
        let lat = localStorage.getItem("lat");
        let lng = localStorage.getItem("lng");
        this.mapImg =
            "https://apis.map.qq.com/ws/staticmap/v2/?center=" +
            lat +
            "," +
            lng +
            "&zoom=12&size=300*172&maptype=roadmap&markers=size:large|" +
            lat +
            "," +
            lng +
            "&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77";
    },
    mounted() {
        this.init(localStorage.getItem("lat"), localStorage.getItem("lng"));
    },
    watch: {
        $route: "fetchData",
    },
    methods: {
        fetchData() {
            this.gongzhonghao = localStorage.getItem("gongzhonghao");
            this.qrcode = localStorage.getItem("qrcode");
        },
        init(latitude, longitude) {
            // console.log(latitude);
            //定义地图中心点坐标
            var control;
            var center = new TMap.LatLng(latitude, longitude);
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            var map = new TMap.Map(document.getElementById("containerFoot"), {
                center: center, //设置地图中心点坐标
                zoom: 12, //设置地图缩放级别
                pitch: 43.5, //设置俯仰角
                rotation: 45, //设置地图旋转角度
                viewMode: "2D",
            });
            //创建并初始化MultiMarker
            var markerLayer = new TMap.MultiMarker({
                map: map, //指定地图容器
                //样式定义
                styles: {
                    //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
                    myStyle: new TMap.MarkerStyle({
                        width: 25, // 点标记样式宽度（像素）
                        height: 35, // 点标记样式高度（像素）
                        src: "/images/logo1.png", //图片路径
                        //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                        anchor: { x: 16, y: 32 },
                    }),
                },
                //点标记数据数组
                geometries: [
                    {
                        id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                        styleId: "myStyle", //指定样式id
                        position: center, //点标记坐标位置
                        properties: {
                            //自定义属性
                            title: "我的公司",
                        },
                    },
                ],
            });
            control = map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
            control.setNumVisible(true);
            control.setPosition(TMap.constants.CONTROL_POSITION.TOP_LEFT);
            control.setPosition(TMap.constants.DEFAULT_CONTROL_ID.TOP_LEFT);
            map.addControl(control);
            // map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
            // 移除比例尺控件
            map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE);
            // 移除旋转控件
            // map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);
        },
        goAboutUs1(id, title, type, infoId) {
            this.$router.push({
                path: "/HelpCenter",
                query: {
                    id: id,
                    title: title,
                    type: type,
                    infoId: infoId,
                },
            });
        },
        goAboutUs2(type, title) {
            this.$router.push({
                path: "/HelpCenter",
                query: {
                    type: type,
                    title: title,
                },
            });
        },
        goAboutUs(type, title) {
            this.$router.push({
                path: "/AboutUs",
                query: {
                    type: type,
                    title: title,
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
.footer {
    width: 100%;
    height: auto;
    background-color: #202020;
    .foot-top {
        width: 1440px;
        height: 180px;
        margin: 0 auto;
        .left1 {
            float: left;
            width: 240px;
            height: 78px;
            margin-top: 55px;
        }
        .cen {
            float: left;
            width: 310px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            margin-left: 110px;
            div {
                width: 115px;
                img {
                    width: 115px;
                    height: 115px;
                    margin-top: 44px;
                }
                span {
                    display: block;
                    width: 100%;
                    text-align: center;
                    color: #000;
                    opacity: 0.4;
                    font-size: 16px;
                    line-height: 30px;
                }
            }
        }
        .map {
            float: left;
            width: 300px;
            height: 172px;
            margin-top: 30px;
            margin-left: 100px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
            }
            &:hover {
                .boxMap {
                    top: -350px;
                    left: -100px;
                }
            }
            .boxMap {
                position: absolute;
                bottom: 10000px;
                left: 1000px;
                width: 500px;
                height: 350px;
                background: #fff;
            }
        }
        .right {
            float: right;
            width: 350px;
            height: 100%;
            text-align: left;
            span {
                display: block;
                color: #000;
                opacity: 0.4;
                font-size: 16px;
                margin-top: 15px;
                line-height: 30px;
                b {
                    font-size: 20px;
                }
            }
            .title {
                margin-top: 30px;
                opacity: 1;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
    .foot-bom {
        width: 100%;
        padding: 15px 0;
        line-height: 35px;
        height: 135px;
        text-align: center;
        color: #ffffff;
        font-size: 14px;
        opacity: 0.2;
        .menu {
            span {
                padding: 0 20px;
                cursor: pointer;
            }
        }
    }
}
</style>
