import axios from "axios";
import { MessageBox, Message, Loading } from "element-ui";
import _ from "lodash";
import store from "@/store";
import { getToken } from "@/utils/auth";

let loading;
//当前正在请求的数量
let needLoadingRequestCount = 0;

//显示loading
function showLoading(target) {
    // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
    // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
    if (needLoadingRequestCount === 0 && !loading) {
        loading = Loading.service({
            lock: true,
            text: "加载中...",
            background: "rgba(255, 255, 255, 0.5)",
            target: target || "body",
        });
    }
    needLoadingRequestCount++;
}

//防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
const toHideLoading = _.debounce(() => {
    if (loading) {
        loading.close();
        loading = null;
    }
}, 300);

//隐藏loading
function hideLoading() {
    needLoadingRequestCount--;
    needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
    if (needLoadingRequestCount === 0) {
        //关闭loading
        toHideLoading();
    }
}

const service = axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? "/api"
            : process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 60000, // request timeout 1分钟
});
process.env.NODE_ENV === "development";

service.interceptors.request.use(
    (config) => {
        // let baseUrl = process.env.VUE_APP_BASE_API
        /*if (store.getters.token) {
            config.headers['Authorization'] = getToken()
        }*/
        config.headers["Authorization"] = getToken();
        //判断当前请求是否设置了不显示Loading
        if (config.headers.showLoading !== false) {
            showLoading(config.headers.loadingTarget);
        }
        return config;
    },
    (error) => {
        // // console.log(error); // for debug
        //判断当前请求是否设置了不显示Loading
        if (error.headers.showLoading !== false) {
            hideLoading();
        }
        // Message.error('请求超时!')
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => {
        if (response.config.headers.showLoading !== false) {
            hideLoading();
        }
        const res = response.data;

        if (res.code == 200) {
            return res;
        } else {
            Message.error(res.msg);
            return;
        }
    },
    (error) => {
        // // console.log(error);
        //判断当前请求是否设置了不显示Loading（不显示自然无需隐藏）
        if (error.config.headers.showLoading !== false) {
            hideLoading();
        }
        Message({
            message: error.msg || "请求失败",
            type: "error",
            duration: 5 * 1000,
        });
        return Promise.reject(error);
    }
);

export default service;
