import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Cookie from "js-cookie";
import Element from "element-ui";
import echarts from "echarts";

import "element-ui/lib/theme-chalk/index.css";
Element.Dialog.props.lockScroll.default = false;
import * as filters from "./filters";
import "@/styles/index.scss"; // global css
// import './permission'
import ImgView from "@/components/imgView/imgView.js";

Vue.use(ImgView);
// import TencentMap from 'vue-tencent-map' // 导入TencentMap组件
// import TencentMap from 'vue-tencent-map'
// 注册TencentMap组件
// 设置腾讯地图API密钥
// TencentMap.init('XUKBZ-U2RYD-W2L4D-PECA6-66JFZ-JVBYS') // YOUR_KEY为你自己的腾讯地图开发者密钥

// Vue.use(TencentMap)
Vue.config.productionTip = false;

Vue.use(Element, {
    size: Cookie.get("size") || "medium",
});

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});

new Vue({
    router,
    store,
    echarts,
    render: (h) => h(App),
}).$mount("#app");
